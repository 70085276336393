import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab,fas,far);

const ServiceContent = [
  {
    icon: ["fas", "code"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "Web Development",
    descriptions: `Working to modern and extremely high standards we build websites and applications for clients ranging from small to enterprise level. With our years of experience we can plan and build sites to be fully scalable up to the highest levels of traffic. Using a variety of Content Management Systems (CMSs) we can create editable sites that allow our clients to have full control over all the elements they require.`,
    delayAnimation: "200",
    aos: "fade-right",
  },
  {
    icon: ["fas", "mobile-alt"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "App Development",
    descriptions: `Building and deploying an iOS or Android app built can be a complex process. We have experience in building apps that are stable across multiple devices whilst ensuring a single code base, allowing for easy updating and deployment across multiple app stores. From the smallest app to the next big thing, we can help make your app requirements a reality.`,
    delayAnimation: "400",
    aos: "fade-left",
  },
  {
    icon: ["fas", "bullseye"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "SEO Services",
    descriptions: `Having trouble being found online, or not being found for the searches you want? Search Engine Optimisation (SEO) is an ever changing and at times confusing subject. We have years of experience implementing strategies to help companies be seen for the search terms they wish to be found for.`,
    delayAnimation: "200",
    aos: "fade-right",
  },{
    icon: ["fab", "modx"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "MODX Development",
    descriptions: `The MODX system is a Content Management Framework (CMF) that makes managing your website a breeze. We have worked with this platform for over 12 years and have a deep understanding of best practices for this complex platform. We am also able to help with existing installations, including security audits and fixes.`,
    delayAnimation: "400",
    aos: "fade-left",
  },
  {
    icon: ["fab", "wordpress"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "WordPress Development",
    descriptions: `The WordPress platform powers nearly half of websites worldwide and supportsa rich ecosystem of plugins and themes. We have years of experience developing high quality websites within WordPress and we're able to build new sites as well as working with existing sites from other developers.`,
    delayAnimation: "200",
    aos: "fade-right",
  },
  {
    icon: ["fas", "layer-group"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "UX/UI Services",
    descriptions: `Using the latest theories and frameworks we are able to gather requirements, design, and build web based projects that meet your business requirements. We have a keen eye for detail and a proven track record of designing functional and intuitive interfaces that bring your vision to life.`,
    delayAnimation: "400",
    aos: "fade-left",
  },{
    icon: ["far","paper-plane"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "Email Marketing",
    descriptions: `From HTML email development and sends, to management of large scale email distribution and reporting, we are able to offer the full service experience for email marketing.`,
    delayAnimation: "200",
    aos: "fade-right",
  },
  {
    icon: ["far","comments"],
    classNames: "col-md-6 col-lg-6 my-3",
    title: "Consultancy",
    descriptions: `Alternatively, if you have a project in mind that doesn't fit into any of these categories then just get in touch and we can get together to make excellent things happen.`,
    delayAnimation: "400",
    aos: "fade-left",
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className={val.classNames}
            key={i}
            data-aos={val.aos}
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <FontAwesomeIcon icon={val.icon} />
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}

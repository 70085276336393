import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>What’s your story? Get in touch</h4>
        <p>
          We are always available to discuss potential projects. 
          Feel free to contact us.
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">hello@pixelwire.uk</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+044 7590 029 407</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;

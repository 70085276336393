import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../contact/Contact";

Modal.setAppElement("#root");

const News = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }


  return (
    <>
      <div className="row">
        <div
          className="col-md-4 m-15px-tb"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="blog-grid" onClick={toggleModalOne}>
            <div className="blog-img">
              <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-1.jpg'}`} alt="blog post"></img>
            </div>
            <div className="blog-info">
              <div className="meta">UX/UI, Web Development, eCommerce, MODX, Prestashop</div>
              <h6>
                <a>
                  Website and eCommerce store supports global book launch
                </a>
              </h6>
            </div>
          </div>
        </div>
        {/* End .col for blog-1 */}

        <div
          className="col-md-4 m-15px-tb"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="blog-grid" onClick={toggleModalTwo}>
            <div className="blog-img">
              <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-2.jpg'}`} alt="blog post"></img>
            </div>
            <div className="blog-info">
              <div className="meta">WordPress, Web Development</div>
              <h6>
                <a>New website for experienced events company</a>
              </h6>
            </div>
          </div>
        </div>
        {/* End .col for blog-2 */}

        <div
          className="col-md-4 m-15px-tb"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div className="blog-grid" onClick={toggleModalThree}>
            <div className="blog-img">
              <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-3.jpg'}`} alt="blog post"></img>
            </div>

            <div className="blog-info">
              <div className="meta">MODX, Web Development, Custom eCommerce solution</div>
              <h6>
                <a>Website relaunch for educational charity</a>
              </h6>
            </div>
          </div>
        </div>
        {/* End .col for blog-3 */}
      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src={`${process.env.PUBLIC_URL + '/img/cancel.svg'}`} alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-1.jpg'}`} alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                    Website and eCommerce store supports global book launch
                    </h2>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>A custom design and build of a MODX based site paired with a Prestashop store to support the launch of an ambitious new book.</p>
                    <p>Built in under 4 days this site supported the global book launch.</p>
                    <blockquote>
                      <p>
                      Pixelwire's professionalism, transparency and thoroughness make working with him a delight. Every step and stage is clear and the work they produce is both stylish and practical. Brilliant and hepful. 
                      </p>
                      <p className="blockquote-footer">
                        <cite title="Source Title">JW Langley, Author of Incredulous Moshoeshoe and the lightning bird</cite>
                      </p>
                    </blockquote>
                  </div>
                  {/* End article content */}
                  <ul className="nav tag-cloud">
                    <li href="#">UX/UI</li>
                    <li href="#">Web Development</li>
                    <li href="#">eCommerce</li>
                    <li href="#">MODX</li>
                    <li href="#">Prestashop</li>
                    <li href="#">API Intergrations</li>
                  </ul>
                  {/* End tag */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Interested in doing a project like this? Send us a message</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src={`${process.env.PUBLIC_URL + '/img/cancel.svg'}`} alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-2.jpg'}`} alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                    New website for experienced events company
                    </h2>
                  </div>
                  {/* End .article-title */}

                  {/* <div className="article-content">
                    <p>I made another site. It was good.</p>
                    <blockquote>
                      <p>
                        Nice quote from Hg3
                      </p>
                      <p className="blockquote-footer">
                        <cite title="Source Title">Charlotte Turpin, Head of something</cite>
                      </p>
                    </blockquote>
                  </div> */}
                  {/* End article content */}
                  <ul className="nav tag-cloud">
                    <li href="#">WordPress</li>
                    <li href="#">Web Development</li>
                    <li href="#">API Intergrations</li>
                  </ul>
                  {/* End tag */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Interested in doing a project like this? Send us a message</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 */}

      {/* Start Modal for Blog-3 */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src={`${process.env.PUBLIC_URL + '/img/cancel.svg'}`} alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src={`${process.env.PUBLIC_URL + '/img/blog/blog-3.jpg'}`} alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                    Website relaunch for educational charity
                    </h2>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    {/* <p>I made a third site. It was good.</p>
                    <blockquote>
                      <p>
                        Nice quote from Melissa
                      </p>
                      <p className="blockquote-footer">
                        <cite title="Source Title">Melissa Furness, Marketing Manager</cite>
                      </p>
                    </blockquote> */}
                  </div>
                  {/* End article content */}
                  <ul className="nav tag-cloud">
                    <li href="#">MODX</li>
                    <li href="#">Web Development</li>
                    <li href="#">Custom eCommerce solution</li>
                    <li href="#">PayPal API Intergrations</li>
                    <li href="#">API Intergrations</li>
                  </ul>
                  {/* End tag */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Interested in doing a project like this? Send us a message</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}

      {/* Start Modal for Blog-4 */}
      
      {/* End  Modal for Blog-4 */}
    </>
  );
};

export default News;

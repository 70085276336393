import React from "react";
import TextLoop from "react-text-loop";

const conctInfo = {
  phone: "+44 7590 029 407",
  email: "hello@pixelwire.uk",
};

const sliderContent = {
  name: "Pixelwire",
  designation: "Full-stack Developer",
  description: `We are a micro-agency specialising in the development of websites and web applications for customers of all sizes.
  We create stylish, modern websites, web services and online stores that deliver ROI for our clients, time after time.`,
  btnText: " Contact us",
};

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+447590029407">{conctInfo.phone}</a>
            <a href="mailto:hello@pixelwire.uk">
              {conctInfo.email}
            </a>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  Hello, we are
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">Web Design and Development</p>
                    <p className="loop-text lead">App Development</p>
                    <p className="loop-text lead">Email Marketing</p>
                    <p className="loop-text lead"> WordPress Specialists</p>
                    <p className="loop-text lead"> MODX Specialists</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="#contactus"
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/img/slider/home-bg.jpg"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;

import React from "react";

const ClientsContent = [
  {
    image: "hg3.png",
    title: "Hg3",
    delay: 100,
  },
  {
    image: "jwlangley.png",
    title: "JW Langley",
    delay: 600,
  },
  {
    image: "senechal.png",
    title: "Senechal",
    delay: 200,
  },
  {
    image: "kohima.png",
    title: "Kohima Educational Trust",
    delay: 300,
  },
  {
    image: "captec.png",
    title: "Captec",
    delay: 400,
  },
  {
    image: "xi-hair.png",
    title: "XI Hair",
    delay: 500,
  },
  {
    image: "cakeordeath.png",
    title: "Cake or Death",
    delay: 700,
  },{
    image: "robhogg.png",
    title: "Rob Hogg",
    delay: 800,
  },
];
export default function Clients() {
  return (
    <>
      <div className="row">
        {ClientsContent.map((val, i) => (
          <div className="col-sm-6 col-md-3 my-3" key={i}>
            <img src={`${process.env.PUBLIC_URL + '/img/clients/' + val.image}`} alt={`${val.title}`} className="img-fluid" data-aos="fade-up" data-aos-delay={`${val.delay}`}/>
          </div>
        ))}
      </div>
    </>
  );
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "naomi-braithwaite",
      desc: `Working with Pixelwire on my site has been a fantastic experience. Their knowledge and problem solving abilities have made the process of building a site for my business as easy as it could be. I look forward to working with them again in the future.`,
      reviewerName: "Naomi Braithwaite",
      designation: "Owner and founder of XI-Hair",
      delayAnimation: "400",
    },
    {
      imageName: "jonty-langley",
      desc: `Pixelwire's professionalism, transparency and thoroughness make working with them a delight. Every step and stage is clear and the work they produce is both stylish and practical. Brilliant and hepful.`,
      reviewerName: "Jonty Langley",
      designation: "Author of Incredulous Moshoeshoe and the lightning bird",
      delayAnimation: "800",
    },
    // {
    //   imageName: "melissa-furness",
    //   desc: `  Lorem Ipsum is simply dummy text of the printing and
    //   typesetting industry. Lorem Ipsum has been the industry's
    //   standard dummy text ever since the 1500s.`,
    //   reviewerName: "Melissa Furness",
    //   designation: "Kohima Educational Trust",
    //   delayAnimation: "",
    // },
    // {
    //   imageName: "robb-hogg",
    //   desc: ` Lorem Ipsum is simply dummy text of the printing and
    //   typesetting industry. Lorem Ipsum has been the industry's
    //   standard dummy text ever since the 1500s.`,
    //   reviewerName: "Rob Hogg",
    //   designation: "skinnywhippet",
    //   delayAnimation: "600",
    // },
    // {
    //   imageName: "charlotte-turpin",
    //   desc: ` Lorem Ipsum is simply dummy text of the printing and
    //   typesetting industry. Lorem Ipsum has been the industry's
    //   standard dummy text ever since the 1500s.`,
    //   reviewerName: "Charlotte Turpin",
    //   designation: "Hg3 event management",
    //   delayAnimation: "200",
    // },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="testimonial-01 media">
              <div className="avatar">
                <img
                  src={`${process.env.PUBLIC_URL + '/img/testimonial/' + val.imageName}.jpg`}
                  alt="review comments"
                ></img>
              </div>
              <div className="media-body">
                <p>{val.desc}</p>
                <h6>{val.reviewerName}</h6>
                <span>{val.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

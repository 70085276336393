import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab);

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg pb-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-lg-12 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Who are we?</h3>
                </div>
                <div className="about-text">
                <p><br></br><h5>Based in the South of Egland, we make digital experiences for some of the largest companies in the world.</h5></p>
                  <p><h4>We are a micro-agency consisting of a team of software engineers and designers with a passion for high quality, precision engineered output. With over 30 years of commercial experience we bring together the best of agency without the extra bulk and overheads of the traditional model.</h4></p>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

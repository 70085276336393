import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderAnimation";
import About from "../../components/about/AboutAnimation";
// import Resume from "../../components/resume/ResumeAnimation";
// import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Testimonials from "../../components/testimonial/TestimonialAnimation";
import Services from "../../components/service/ServiceAnimation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Clients from "../../components/clients/clients";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/contact/Contact";
import ContactInfo from "../../components/contact/ContactInfo";
// import Map from "../../components/contact/Map";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeOne = () => {
  useDocumentTitle(
    "Pixelwire - Micro-agency - Oxfordshire and Berkshire"
  );
  return (
    <div className="main-left theme-dark">
      <Header />
      <Slider />
      <About />
      <section className="theme-light dark-bg" id="services">
        <div className="container">
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title" id="services">
            <h3>What services can we offer?</h3>
          </div>
          <Services />
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
          </div>
        </section>
        <section className="theme-light dark-bg" id="why">
          <div className="container">
          <div className="title">
            <h3>Why use a micro-agency?</h3>
          </div>
          <p>You might ask yourself why you would want to use a micro-agency compared to a traditional agency model.<br></br><br></br>Below are a few reasons we believe a micro-agency may be better for your business than a traditional full scale agency.</p><br></br>
          <div className="row align-items-center justify-content-center">
            <div
              className="col-12 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div>
                <h5>Micro agencies have just the right team</h5>
                <p>With a micro agency you get just the people you need, and no one else. No extra team members that aren’t relevant to your project will be needed and so costs will be significantly lower than that of a traditional agency. If other team members are needed for a specific project they can be added as required.</p>
              </div>
              <br></br><br></br>
            </div>
            
            <div
              className="col-12 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div>
                <h5>Micro agencies allow communication directly to the people doing the work</h5>
                <p>In a traditional agency model the client would communicate to an account manager or account executive. That person would then communicate with the appropriate person doing the work, summarise the conversation and send that back to the client. This can lead to requirements getting lost in translation and complex things being missed due to the communication barrier. In a micro agency you get direct communication to the people doing the work. If you have a question it can be answered quickly by the person who actually knows the answer because they are doing the work which can help you faster, meaning less friction and an easier path to success.</p>
              </div><br></br><br></br>
            </div>
            <div
              className="col-12 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div>
                <h5>Get the same quality from the same people for lower cost</h5>
                <p>Micro agencies allow you to get the same quality of output without having to hire the whole agency. Micro agencies have the same calibre and experience of traditional agencies but without the overheads and costs, or the traditional agency markup.</p>
              </div><br></br><br></br>
            </div>
            <div
              className="col-12 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div>
                <h5>Micro agencies have a smaller client base and so have more time to focus those clients and take on projects</h5>
                <p>Without the high client levels of traditional agencies, a micro agency is able to focus on clients and provide them with more one-on-one time to offer a more personal and responsive service. A micro agency is able to be more agile and flexible and take on rush projects as required by their clients.</p>
              </div>
            </div>
          </div>
          
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
          </div>
      </section>
      <section className="theme-light dark-bg" id="testimonials">
          <div className="container">
            <div className="title">
              <h3>Testimonials</h3>
            </div>

            <Testimonials />
            {/* End Testimonaial */}
          </div>
      </section>
      <div className="dark-bg">
      <div className="container">
         {/* separated */}
         <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
        </div>
      </div>
      <section id="clients" className="section theme-light dark-bg py-6">
        <div className="container">
          <div className="title">
            <h3>Clients we've worked with</h3>
          </div>
          {/* <Portfolio /> */}
          <Clients />
        </div>
      </section>

      {/* End Portfolio Section */}

      <section id="work" className="section">
        <div className="container">
          <div className="title">
            <h3>Work Examples</h3>
          </div>
          <Blog />
        </div>
      </section>
      {/* End Portfolio Section */}

      <section id="contactus" className="section theme-light dark-bg">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-xl-4 m-15px-tb"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <ContactInfo />
            </div>
            {/* End Contact info */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="contact-form">
                <h4>Say Something</h4>
                <Contact />
              </div>
            </div>
            {/* End contact form */}

            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              {/* <Map /> */}
              {/* End google-map */}
            </div>
            {/* End Col */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}

      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Contact Section */}
    </div>
  );
};

export default HomeOne;
